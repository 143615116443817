import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Redirect from 'react-router-dom/Redirect';

import {
  Alert,
  Card, CardText, CardBody, CardTitle,
  Form, FormGroup, Input,
  Col, Button, Row,
} from 'reactstrap';

import ArrivaTouringLogo from '../../images/arriva_touring_logo.png';

import { getProject, selectProject } from '../reducers/SessionReducer';

class Login extends Component {
  static propTypes = {
    // Dispatch props
    getProject: PropTypes.func.isRequired,

    // State props
    loadingProject: PropTypes.bool.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
      state: PropTypes.shape({
        from: PropTypes.shape({
          pathname: PropTypes.string,
        }),
      }),
    }),
    project: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  };

  state = {
    error: undefined,
    projectCode: undefined,
    redirectToProject: false,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.project && this.props.project !== nextProps.project) {
      this.setState({ redirectToProject: true });
    }

    if (!nextProps.loadingProject && nextProps.project === false) {
      this.setState({ error: 'Project niet gevonden' });
    }

    if (!this.props.loadingProject && nextProps.loadingProject) {
      this.setState({ error: undefined });
    }
  }

  submit(e) {
    const { getProject } = this.props;
    const { projectCode } = this.state;

    e.preventDefault();

    if (projectCode) {
      getProject(projectCode).finally(() => null);
    } else {
      this.setState({ error: 'Voer een projectcode in' });
    }
  }

  render() {
    const { project } = this.props;
    const { error, redirectToProject } = this.state;

    if (redirectToProject) return <Redirect to={`/project/${project}`} />;

    return (
      <Row>
        <Col sm="12" md={{ size: 6, offset: 3 }} lg={{ size: 4, offset: 4 }} style={{ marginTop: 100 }}>
          <img className="logo" src={ArrivaTouringLogo} alt="Arriva Touring logo" />

          {error && <Alert color="danger">{error}</Alert>}

          <Card>
            <CardBody>
              <CardTitle>Inloggen</CardTitle>
              <CardText>Vul hier de projectcode in waarvan u alle bussen wilt bekijken.</CardText>

              <Form onSubmit={e => this.submit(e)}>
                <FormGroup>
                  <Input
                    type="text"
                    name="projectCode"
                    id="projectCode"
                    placeholder="projectcode"
                    onChange={e => this.setState({ projectCode: e.target.value })}
                  />
                </FormGroup>
              </Form>

              <Button
                block
                color="primary"
                onClick={(e) => this.submit(e)}
              >Inloggen</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingProject: state.session.loadingProject,
  project: selectProject(state),
});

const mapDispatchToProps = {
  getProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
