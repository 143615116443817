import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Alert,
  Badge,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
} from 'reactstrap';

import {
  getDataByProjectId,
  selectLat,
  selectLng,
  selectProject,
  selectSessionsByProject,
  selectShowASLMessage,
  selectZoom,
  setShowASLMessage,
  toggleProjectShowASLMessage,
} from '../reducers/SessionReducer';

import ArrivaTouringLogo from '../../images/arriva_touring_logo.png';
import { sessions as sessionPropDefinition } from '../proptypes';

let Map;

class Viewer extends Component {
  static propTypes = {
    // Dispatch props
    getDataByProjectId: PropTypes.func.isRequired,

    // State props
    project: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    sessions: PropTypes.arrayOf(sessionPropDefinition),
  };

  state = {
    disableClickLogo: false,
    lat: undefined,
    lng: undefined,
    mounted: false,
    zoom: undefined,
  };

  /**
   * Keep track of the amount of times the user pressed the logo
   *
   * @type {number}
   */
  counter = 0;

  /**
   * The timer that will reset the counter
   *
   * @type {undefined|number}
   */
  timer = undefined;

  /**
   * Method that will reset the counter
   */
  resetCounter = () => { this.counter = 0; };

  componentDidMount() {
    const { getDataByProjectId, lat, lng, project, sessions, zoom } = this.props;

    if (sessions === undefined) {
      getDataByProjectId(project);
    }

    this.setState({ lat, lng, zoom });

    Map = require('../components/Map').default;

    this.setState({ mounted: true });

    setInterval(() => getDataByProjectId(project), 10000);
  }

  /**
   * Method that will handle the pressing of the logo
   *
   * :::===  :::===== :::===== :::====  :::===== :::====
   * :::     :::      :::      :::  === :::      :::====
   *  =====  ======   ===      =======  ======     ===
   *     === ===      ===      === ===  ===        ===
   * ======  ========  ======= ===  === ========   ===
   */
  onPressLogo = () => {
    if (this.state.disableClickLogo) return;

    if (this.timer) clearTimeout(this.timer);

    // Increment the timer
    this.counter += 1;

    // When the timer is 10, toggle the asl message
    if (this.counter === 10) {
      this.counter = 0;

      const { project, toggleProjectShowASLMessage } = this.props;

      this.setState({ disableClickLogo: true });

      toggleProjectShowASLMessage(project)
        .then(() => this.setState({ disableClickLogo: false }));
    } else {
      this.timer = setTimeout(this.resetCounter, 500);
    }
  };

  render() {
    const { history, match, project, sessions, showASLMessage } = this.props;
    const { lat, lng, mounted, zoom } = this.state;

    return (
      <Container fluid>
        <Row className="viewer">
          <Col xs="12" md="8" lg="10" style={{ padding: 0 }}>
            <div id="map-container">
              {(mounted && Map) &&
                <Map
                  history={history}
                  lat={lat}
                  lng={lng}
                  match={match}
                  project={project}
                  sessions={sessions}
                  zoom={zoom}
                  setLatLng={(lat, lng) => this.setState({ lat, lng })}
                  setZoom={zoom => this.setState({ zoom })}
                />
              }
            </div>
          </Col>

          <Col xs="12" md="4" lg="2" className="session-list">
            <img className="logo" src={ArrivaTouringLogo} alt="Arriva Touring logo" onClick={this.onPressLogo} />

            {showASLMessage && (
              <Alert color="danger">Er is een bus uitgevallen!</Alert>
            )}

            {(!sessions || (sessions && sessions.length === 0)) && <h3>Geen bussen</h3>}

            {(sessions && sessions.length > 0) &&
              <div>
                <h3>Bussen</h3>

                <ListGroup>
                  {sessions.map(session => {
                    const createdDate = new Date(session.created);
                    const lastLocationDate = new Date(session.last_location_datetime);
                    const olderThan15Minutes = (new Date() - lastLocationDate) > (15*60*1000);

                    return (
                      <ListGroupItem
                        key={`listgroup-item-${session.uuid}`}
                        onClick={() => {
                          const { location: { coordinates: coords } } = session;

                          this.setState({ lat: coords[0], lng: coords[1], zoom: 10 });
                        }}
                      >
                        <ListGroupItemHeading>
                          {session.description}

                          <Badge
                            color="primary"
                            className="float-right"
                            style={{ backgroundColor: '#37A7D9' }}
                          >
                            {session.markerId}
                          </Badge>
                        </ListGroupItemHeading>
                        {createdDate.toLocaleTimeString('nl-NL', {
                          year: 'numeric', month: 'long', day: 'numeric',
                        })}

                        {!session.location &&
                          <small className="no-location">Nog geen locatie ontvangen</small>
                        }

                        {(session.location && session.last_location_datetime) &&
                          <small className={`last-location ${olderThan15Minutes ? 'over15' : ''}`}>
                            Laatste locatie ontvangen om: {lastLocationDate.toLocaleTimeString('nl-NL')}
                          </small>
                        }
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </div>
            }
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state, props) => ({
  lat: selectLat(props),
  lng: selectLng(props),
  project: selectProject(state, props),
  sessions: selectSessionsByProject(state, props),
  showASLMessage: selectShowASLMessage(state),
  zoom: selectZoom(props),
});

const mapDispatchToProps = {
  getDataByProjectId,
  setShowASLMessage,
  toggleProjectShowASLMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Viewer);
