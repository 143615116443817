import { propEq, path, pathOr, prop, reject, sortBy } from 'ramda';
import { createSelector } from 'reselect';

import { get, patch } from './ApiReducer';

// ------------------------------------
// Constants
// ------------------------------------
export const SESSION_LOADING = 'SESSION_LOADING';
export const SESSION_LOADING_DONE = 'SESSION_LOADING_DONE';
export const SESSION_LOADING_PROJECT = 'SESSION_LOADING_PROJECT';
export const SESSION_LOADING_PROJECT_DONE = 'SESSION_LOADING_PROJECT_DONE';
export const SESSION_LOADING_SESSIONS = 'SESSION_LOADING_SESSIONS';
export const SESSION_LOADING_SESSIONS_DONE = 'SESSION_LOADING_SESSIONS_DONE';
export const SESSION_DESTROY = 'SESSION_DESTROY';
export const SESSION_SET_SHOW_ASL_MESSAGE = 'SESSION_SET_SHOW_ASL_MESSAGE';
export const SESSION_SET_PROJECT = 'SESSION_SET_PROJECT';
export const SESSION_SET_SESSIONS = 'SESSION_SET_SESSIONS';

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  showASLMessage: false,
  loading: false,
  loadingProject: false,
  loadingSessions: false,
  project: undefined,
  sessions: undefined,
};

// ------------------------------------
// Actions
// ------------------------------------
export const loading = () => ({ type: SESSION_LOADING });
export const loadingDone = () => ({ type: SESSION_LOADING_DONE });
export const loadingProject = () => ({ type: SESSION_LOADING_PROJECT });
export const loadingProjectDone = () => ({ type: SESSION_LOADING_PROJECT_DONE });
export const loadingSessions = () => ({ type: SESSION_LOADING_SESSIONS });
export const loadingSessionsDone = () => ({ type: SESSION_LOADING_SESSIONS_DONE });

export const setShowASLMessage = show => ({ type: SESSION_SET_SHOW_ASL_MESSAGE, payload: show });
export const setProject = project => ({ type: SESSION_SET_PROJECT, payload: project });
export const setSessions = sessions => ({ type: SESSION_SET_SESSIONS, payload: sessions });

export const getDataByProjectId = projectId => async (dispatch) => {
  await dispatch(loadingSessions());

  await dispatch(get({ path: `/data/${projectId}` }))
    .then(async ({ data: { show_asl_message, sessions } }) => {
      await dispatch(setShowASLMessage(show_asl_message));
      await dispatch(setSessions(sessions));
    })
    .catch(err => console.log('Error: ', err))
    .then(() => dispatch(loadingSessionsDone()));
};

export const getProject = name => async (dispatch) => {
  await dispatch(loadingProject());

  await dispatch(get({path: `/project/${name}`}))
    .then(async ({data: {uuid}}) => {
      await dispatch(setProject(uuid));
      await dispatch(getDataByProjectId(uuid));
    })
    .catch(async (err) => {
      console.log(err);
      return dispatch(setProject(false))
    })
    .then(() => dispatch(loadingProjectDone()));
};

export const toggleProjectShowASLMessage = projectId => dispatch =>
  dispatch(patch({ path: `/project/${projectId}/notification`, body: {} }))
    .then(({ data: { show }}) => dispatch(setShowASLMessage(show)))
    .catch((err) => console.log(err));

export const getRedirectForAlias = alias => dispatch => dispatch(get({ path: `/alias/${alias}` }));

export const destroy = () => dispatch => dispatch({ type: SESSION_DESTROY });

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  // Loaders
  [SESSION_LOADING]: state => ({ ...state, loading: true }),
  [SESSION_LOADING_DONE]: state => ({ ...state, loading: false }),
  [SESSION_LOADING_PROJECT]: state => ({ ...state, loadingProject: true }),
  [SESSION_LOADING_PROJECT_DONE]: state => ({ ...state, loadingProject: false }),
  [SESSION_LOADING_SESSIONS]: state => ({ ...state, loadingSessions: true }),
  [SESSION_LOADING_SESSIONS_DONE]: state => ({ ...state, loadingSessions: false }),

  // Other
  [SESSION_SET_SHOW_ASL_MESSAGE]: (state, action) => ({ ...state, showASLMessage: action.payload }),
  [SESSION_SET_PROJECT]: (state, action) => ({ ...state, project: action.payload }),
  [SESSION_SET_SESSIONS]: (state, action) => ({ ...state, sessions: action.payload }),
  [SESSION_DESTROY]: () => ({ ...initialState }),
};

// ------------------------------------
// State selectors
// ------------------------------------
export const selectProject = ({ session: { project } }, props) =>
  path(['computedMatch', 'params', 'project'], props) || path(['match', 'params', 'project'], props) || project;
export const selectSessions = state => path(['session', 'sessions'], state);
export const selectShowASLMessage = state => path(['session', 'showASLMessage'], state);

// ------------------------------------
// Prop selectors
// ------------------------------------
export const selectAlias = props => pathOr(path(['match', 'params', 'alias'], props), ['computedMatch', 'params', 'alias'], props);

// Defaults based on: https://www.google.com/maps/place/Nederland/@52.2076831,4.1585786,8z
export const selectLat = props =>
  pathOr(pathOr(52.20760667286523, ['match', 'params', 'lat'], props), ['computedMatch', 'params', 'lat'], props);
export const selectLng = props =>
  pathOr(pathOr(4.158496856689454, ['match', 'params', 'lng'], props), ['computedMatch', 'params', 'lng'], props);
export const selectZoom = props =>
  pathOr(pathOr(8, ['match', 'params', 'zoom'], props), ['computedMatch', 'params', 'zoom'], props);


export const selectSessionsByProject = createSelector(
  [selectSessions], (sessions) => {
    if (!sessions) return undefined;

    const orderedSessions = sortBy(prop('description'))(reject(propEq('description', null))(sessions));

    let markerIds = 1;

    return orderedSessions.map(s => ({ markerId: markerIds++, ...s }));
  }
);

// ------------------------------------
// Reducer
// ------------------------------------
export default function sessionReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
