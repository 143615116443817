import { connect } from 'react-redux';
import React from 'react';
import Redirect from 'react-router-dom/Redirect';
import Route from 'react-router-dom/Route';
import Switch from 'react-router-dom/Switch';
import withRouter from 'react-router-dom/withRouter';

import { selectProject } from './reducers/SessionReducer';
import AliasHandler from './layouts/AliasHandler';
import Viewer from './layouts/Viewer';
import Login from './layouts/Login';

import '../scss/App.scss';

/* eslint-disable react/prop-types */

const PrivateRoute = ({
  component: Component,
  childComponent,
  project,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => (
      (project !== undefined) ? (
          <Component {...props} childComponent={childComponent} />
        ) :
        <Redirect to="/login" />
    )}
  />
);

const ConnectedPrivateRoute = withRouter(connect((state, props) =>
  ({ project: selectProject(state, props) }))(PrivateRoute));

const App = () => (
  <Switch>
    <ConnectedPrivateRoute path="/project/:project/:lat?/:lng?/:zoom?" exact component={Viewer} />

    <Route path="/:alias" exact component={AliasHandler} />
    <Route path="/" component={Login} />
  </Switch>
);

export default App;
