// milliseconds, minimum time the server should respond in, otherwise an error is trigger
const FETCH_TIMEOUT = 2000;
// count, how many times the request is tried again
const FETCH_RETRIES = 3;
// milliseconds, wait to retry request
const FETCH_RETRY_DELAY = 1000;

// ------------------------------------
// Constants
// ------------------------------------
export const API_SET_DATA = 'API_SET_DATA';
export const API_SET_ENDPOINT_HOST = 'API_SET_ENDPOINT_HOST';
export const API_SET_ENDPOINT_PATH = 'API_SET_ENDPOINT_PATH';

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  endpointHost: '',
  endpointPath: '',
  headers: {
    'Content-Type': 'application/json',
  },
  version: null,
};

const logNetworkFailure = ({ apiEndpoint, method, error }) => {
  const errorString = `Could not connect to server: ${apiEndpoint}`;

  // if (__DEV__) {
    console.log(errorString, method, error.message);
  // } else {
  //   if (error.message === 'Network request failed')
  //     return false;
  //   Raven.captureException(errorString, {
  //     tags: { reducer: 'Api', method, message: error.message },
  //   });
  // }
};

// ------------------------------------
// Actions
// ------------------------------------
export const setEndpointHost = (host) => async dispatch => dispatch({ type: API_SET_ENDPOINT_HOST, data: host });
export const setEndpointPath = (path) => async dispatch => dispatch({ type: API_SET_ENDPOINT_PATH, data: path });

// A wrapper for fetch which handles timeouts
const fetchTimeoutWrapper = (apiEndpoint, fetchParams) => {
  let didTimeOut = false;

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      didTimeOut = true;
      reject(new Error('Request timed out'));
    }, FETCH_TIMEOUT);

    fetch(apiEndpoint, fetchParams)
      .then((response) => {
        // Clear the timeout as cleanup
        clearTimeout(timeout);

        if (!didTimeOut) resolve(response);
      })
      .catch((err) => {
        // Rejection already happened with setTimeout
        if (didTimeOut) return;

        // Reject with error
        reject(err);
      });
  });
};

const request = ({ path, body, method }) => async (dispatch, getState) => {
  const { endpointHost, endpointPath } = getState().api;
  const apiEndpoint = encodeURI(`${endpointHost}${endpointPath}${path}`);

  const fetchParams = {
    headers: getHeaders(getState()),
  };

  if (!method)
    return Promise.reject(new Error('no method param given to request'));

  fetchParams.method = method;

  if (method === 'POST' || method === 'PATCH') {
    if (!body)
      return Promise.reject(new Error('no body param given to request'));
    fetchParams.body = JSON.stringify(body);
  }

  return new Promise((resolve, reject) => {
    const wrappedRequest = async (retryCount) => {
      try {
        const response = await fetchTimeoutWrapper(apiEndpoint, fetchParams);
        const result = await response.json();

        if (result.error) {
          const err = new Error(result.error);

          if (result.message) {
            err.original_message = result.message;
          }

          return reject(err);
        }

        return resolve(result);
      } catch (error) {
        if (retryCount > 0) {
          retry(retryCount);
        } else {
          logNetworkFailure({ apiEndpoint, method, error });
          return reject(error);
        }
      }
    };

    const retry = (retryCount) => {
      setTimeout(() => {
        wrappedRequest(--retryCount);
      }, FETCH_RETRY_DELAY);
    };

    wrappedRequest(FETCH_RETRIES);
  });
};

export const get = ({ path }) => request({ path, method: 'GET' });
export const post = ({ path, body }) => request({ path, body, method: 'POST' });
export const patch = ({ path, body }) => request({ path, body, method: 'PATCH' });

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [API_SET_DATA]: (state, { data }) => ({ ...state, [`${data.type}`]: data.result }),
  [API_SET_ENDPOINT_HOST]: (state, payload) => ({ ...state, endpointHost: payload.data }),
  [API_SET_ENDPOINT_PATH]: (state, payload) => ({ ...state, endpointPath: payload.data }),
};

// ------------------------------------
// Selectors
// ------------------------------------
export function getHeaders(state) {
  const { api: { headers } } = state;

  return { ...headers };
}

// ------------------------------------
// Reducer
// ------------------------------------
export default function apiReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
