import React, { Component } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { reject, propEq, mergeRight } from 'ramda';
import { AttributionControl, Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';

import { sessions as sessionPropDefinition } from '../proptypes';
import 'leaflet/dist/leaflet.css';
import './CustomMarker/leaflet.awesome-markers.scss';

require('./CustomMarker');

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

class Map extends Component {
  static propTypes = {
    // Own props
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sessions: PropTypes.arrayOf(sessionPropDefinition),
    setLatLng: PropTypes.func.isRequired,
    setZoom: PropTypes.func.isRequired,
    zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  };

  constructor (props) {
    super(props);
    this.recalculateHeight = this.recalculateHeight.bind(this);

    this.state = {
      height: 1024,
    };
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.recalculateHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.recalculateHeight);
  }

  componentDidMount() {
    setTimeout(() => this.recalculateHeight(), 500);
  }

  componentDidUpdate() {
    this.map.leafletElement.invalidateSize();
  }

  recalculateHeight() {
    this.setState({ height: document.getElementById('map-container').clientHeight });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { lat, lng, zoom } = nextProps;

    if (lat !== this.props.lat || lng !== this.props.lng || zoom !== this.props.zoom) {
      this.updateHistoryState(lat, lng, zoom);
    }
  }

  updateHistoryState(_lat = null, _lng = null, _zoom = null) {
    let { match: { path }, project, lat, lng, zoom } = this.props;

    // Merge the new params with the known this.props params
    const pathParams = mergeRight({ lat, lng, zoom })({
      ...(_lat ? { lat: _lat } : {}),
      ...(_lng ? { lng: _lng } : {}),
      ...(_zoom ? { zoom: _zoom } : {}),
      project,
    });

    // path unreplaced holds: /project/:project/:lat?/:lng?/:zoom?
    for (let k in pathParams) {
      path = path.replace(new RegExp('(:' + k + '\\??)'), pathParams[k]);
    }

    this.props.history.push(path);
  }

  render() {
    let { lat, lng, sessions, zoom } = this.props;
    const { height } = this.state;

    sessions = reject(propEq('location', null))(sessions || []);

    return (
      <LeafletMap
        ref={ref => this.map = ref}
        center={[ lat, lng ]}
        zoom={zoom}
        style={{ height }}
        onZoomend={({ target: { _zoom } }) => {
          this.props.setZoom(_zoom);
        }}
        onMoveend={({ target }) => {
          const { lat, lng } = target.getCenter();
          this.props.setLatLng(lat, lng);
        }}
        attributionControl={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {sessions && sessions.map(session => (
          <Marker
            key={`marker-${session.uuid}`}
            position={session.location.coordinates}
            icon={L.AwesomeMarkers.icon({
              markerColor: 'blue',
              iconContent: session.markerId,
            })}
          />
        ))}

        <AttributionControl position="bottomright" prefix="Arriva Touring B.V." />
      </LeafletMap>
    );
  }
}

export default Map;
