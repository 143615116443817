import PropTypes from 'prop-types';

export const sessions = PropTypes.shape({
  created: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  last_location_datetime: PropTypes.string,
  location: PropTypes.shape({
    geometry: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number),
      type: PropTypes.string,
    }),
    properties: PropTypes.shape({
      name: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
  uuid: PropTypes.string.isRequired,
});
