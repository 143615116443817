import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import BrowserRouter from 'react-router-dom/BrowserRouter';

import App from './app';
import configureStore from './app/modules/store';

const store = configureStore(window.__PRELOADED_STATE__);

hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// if (module.hot) {
//   module.hot.accept();
// }
