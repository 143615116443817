import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Redirect from 'react-router-dom/Redirect';

import { getRedirectForAlias, selectAlias } from '../reducers/SessionReducer';

class AliasHandler extends Component {
  static propTypes = {
    // Dispatch props
    getRedirectForAlias: PropTypes.func.isRequired,

    // State props
    alias: PropTypes.string,
  };

  state = {
    redirect: undefined,
  };

  componentDidMount() {
    const { alias, getRedirectForAlias } = this.props;

    getRedirectForAlias(alias)
      .then(({ data }) => {
        const uri = new RegExp('^/project/[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$');

        if (uri.test(data)) {
          this.setState({ redirect: data });
        }
      })
      .catch(() => this.setState({ redirect: false }));
  }

  render() {
    const { redirect } = this.state;

    // There is an error
    if (redirect === false) return <h2>Error: Kon alias niet vinden</h2>;

    // Redirect
    if (redirect) return <Redirect to={`${redirect}`} />;

    // Show loader
    return <h2>Laden...</h2>;
  }
}

const mapStateToProps = (state, props) => ({
  alias: selectAlias(props),
});

const mapDispatchToProps = {
  getRedirectForAlias,
};

export default connect(mapStateToProps, mapDispatchToProps)(AliasHandler);
